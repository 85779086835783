import { useCurrentUser } from 'FrontRoyalAngular';

import { useMemo } from 'react';
import { getProgramInclusion } from 'Users';

export function useAdmissionsTab() {
    const currentUser = useCurrentUser();
    const programInclusion = getProgramInclusion(currentUser);

    return useMemo(
        () => ({
            showAdmissionsTab: !programInclusion,
        }),
        [programInclusion],
    );
}
