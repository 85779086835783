import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequiredCurrentUser } from 'FrontRoyalAngular';

import headerImageMobile from 'images/resources/resources-academic-and-program-support-header.png';
import headerImageMobileRetina from 'images/resources/resources-academic-and-program-support-header@2x.png';
import interviewsPersonImageMobile from 'images/resources/resources-academic-and-program-support-interviews-person.png';
import interviewsPersonImageMobileRetina from 'images/resources/resources-academic-and-program-support-interviews-person@2x.png';
import { getCohort } from 'Users';
import { getProgramConfigValue } from 'Program';
import { ROUTES } from '../ResourcesRoutes';
import { CategoryResultsSection } from '../CategoryResultsSection/CategoryResultsSection';
import { useSearchResults } from '../useSearchResults';
import { type CategoryDescriptionSectionProps } from '../CategoryDescriptionSection/CategoryDescriptionSection.types';
import { CategoryDescriptionSection } from '../CategoryDescriptionSection';

export function AcademicAndProgramSupport() {
    const { t } = useTranslation('back_royal');
    const currentUser = useRequiredCurrentUser();
    // We can safely typecast these variables because routing to this page is only
    // possible if you're a logged-in user with a cohort.
    const cohort = getCohort(currentUser)!;
    const isExecEd = getProgramConfigValue(cohort.programType, 'isExecEd');

    const searchResults = useSearchResults({
        categorySlug: ROUTES.RESOURCES.$.ACADEMIC_AND_PROGRAM_SUPPORT.relativePath,
        searchAllSite: false,
    });

    const categoryDescription: CategoryDescriptionSectionProps = useMemo(
        () => ({
            header: {
                title: t('resources.resources.academic_and_program_support_title'),
                image: {
                    mobileImage: {
                        width: 40,
                        height: 40,
                        images: {
                            default: headerImageMobile,
                            retina: headerImageMobileRetina,
                        },
                    },
                    alt: '',
                },
            },
            content: [
                {
                    title: t('resources.resources.academic_and_program_support_content_one_title'),
                    description: !isExecEd
                        ? t('resources.resources.academic_and_program_support_content_one_description')
                        : '',
                    subContent: isExecEd
                        ? [
                              {
                                  title: t('resources.resources.advising'),
                                  description: t('resources.resources.advising_description'),
                              },
                              {
                                  title: t('resources.resources.faculty'),
                                  description: t('resources.resources.faculty_description'),
                              },
                              {
                                  title: t('resources.resources.research'),
                                  description: t('resources.resources.research_description'),
                              },
                          ]
                        : [],
                },
                {
                    title: t('resources.resources.academic_and_program_support_content_two_title'),
                    description: t(
                        `resources.resources.academic_and_program_support_content_two_description${
                            isExecEd ? '_short' : ''
                        }`,
                    ),
                },
            ],
            image: {
                mobileImage: {
                    width: 413,
                    height: 235,
                    images: {
                        default: interviewsPersonImageMobile,
                        retina: interviewsPersonImageMobileRetina,
                    },
                },
                alt: 'Interviews person illustration',
            },
        }),
        [t, isExecEd],
    );

    return (
        <div className="tw-bg-white">
            <CategoryDescriptionSection {...categoryDescription} />
            <CategoryResultsSection {...searchResults} />
        </div>
    );
}
