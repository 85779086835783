import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { useTranslation } from 'react-i18next';
import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { typedBoolean } from 'FrontRoyalAngular/misc';
import { ProgramType } from 'Program';
import { useSearchResults } from '../useSearchResults';
import { SearchResultsSection } from '../SearchResultsSection';
import { useAdmissionsTab } from '../useAdmissionsTab';
import { ArticleCategoryId, ArticleSiteId } from '../Resources.types';
import { ROUTES } from '..';

export function SearchResults() {
    const { t } = useTranslation('back_royal');
    const { showAdmissionsTab } = useAdmissionsTab();
    const [{ query }] = useTypedSearchParams(ROUTES.RESOURCES.SEARCH);
    const { articlesData, status } = useSearchResults({ query, searchAllSite: false });
    const searchAllResult = useSearchResults({ query, searchAllSite: true });

    // FIXME: migrate to useCurrentUser hook. Will need to migrate helpScoutArticleSiteId from schedulable.js to ProgramTypeConfigs.ts
    const currentUser = useCurrentUserIguanaObject();
    const siteId = currentUser?.relevantCohort?.helpScoutArticleSiteId;
    const programType = currentUser?.relevantCohort?.programType;

    const categoriesTitles = {
        [ArticleCategoryId.quanticMbaEmbaAdmissions]: t('resources.resources.admissions'),
        [ArticleCategoryId.quanticMbaEmbaAcademicSupport]: t('resources.resources.academic_and_program_support_title'),
        [ArticleCategoryId.quanticMbaEmbaCareerServices]: t('resources.resources.career_services'),
        [ArticleCategoryId.quanticMbaEmbaLibraryServices]: t('resources.resources.library_services'),
        [ArticleCategoryId.quanticMbaEmbaStudentEvents]: t('resources.resources.student_events'),

        [ArticleCategoryId.quanticMsbaAdmissions]: t('resources.resources.admissions'),
        [ArticleCategoryId.quanticMsbaAcademicSupport]: t('resources.resources.academic_and_program_support_title'),
        [ArticleCategoryId.quanticMsbaCareerServices]: t('resources.resources.career_services'),
        [ArticleCategoryId.quanticMsbaLibraryServices]: t('resources.resources.library_services'),
        [ArticleCategoryId.quanticMsbaStudentEvents]: t('resources.resources.student_events'),

        [ArticleCategoryId.quanticMsseAdmissions]: t('resources.resources.admissions'),
        [ArticleCategoryId.quanticMsseAcademicSupport]: t('resources.resources.academic_and_program_support_title'),
        [ArticleCategoryId.quanticMsseCareerServices]: t('resources.resources.career_services'),
        [ArticleCategoryId.quanticMsseLibraryServices]: t('resources.resources.library_services'),
        [ArticleCategoryId.quanticMsseStudentEvents]: t('resources.resources.student_events'),

        [ArticleCategoryId.valarAdmissions]: t('resources.resources.admissions'),
        [ArticleCategoryId.valarAcademicSupport]: t('resources.resources.academic_and_program_support_title'),
        [ArticleCategoryId.valarCareerServices]: t('resources.resources.career_services'),
        [ArticleCategoryId.valarLibraryServices]: t('resources.resources.library_services'),
        [ArticleCategoryId.valarStudentEvents]: t('resources.resources.student_events'),

        [ArticleCategoryId.execEdAdmissions]: t('resources.resources.admissions'),
        [ArticleCategoryId.execEdAcademicSupport]: t('resources.resources.academic_and_program_support_title'),
        [ArticleCategoryId.execEdCareerServices]: t('resources.resources.career_services'),
        [ArticleCategoryId.execEdLibraryServices]: t('resources.resources.library_services'),
        [ArticleCategoryId.execEdStudentEvents]: t('resources.resources.student_events'),
    };

    let quanticCategories: (ArticleCategoryId | null)[] = [];
    switch (programType) {
        case ProgramType.msba:
            quanticCategories = [
                showAdmissionsTab ? ArticleCategoryId.quanticMsbaAdmissions : null,
                ArticleCategoryId.quanticMsbaAcademicSupport,
                ArticleCategoryId.quanticMsbaCareerServices,
                ArticleCategoryId.quanticMsbaLibraryServices,
                ArticleCategoryId.quanticMsbaStudentEvents,
            ];
            break;
        case ProgramType.msse:
            quanticCategories = [
                showAdmissionsTab ? ArticleCategoryId.quanticMsseAdmissions : null,
                ArticleCategoryId.quanticMsseAcademicSupport,
                ArticleCategoryId.quanticMsseCareerServices,
                ArticleCategoryId.quanticMsseLibraryServices,
                ArticleCategoryId.quanticMsseStudentEvents,
            ];
            break;
        default: // MBA/EMBA
            quanticCategories = [
                showAdmissionsTab ? ArticleCategoryId.quanticMbaEmbaAdmissions : null,
                ArticleCategoryId.quanticMbaEmbaAcademicSupport,
                ArticleCategoryId.quanticMbaEmbaCareerServices,
                ArticleCategoryId.quanticMbaEmbaLibraryServices,
                ArticleCategoryId.quanticMbaEmbaStudentEvents,
            ];
    }

    const categories = {
        [ArticleSiteId.quantic]: quanticCategories,
        [ArticleSiteId.valar]: [
            showAdmissionsTab ? ArticleCategoryId.valarAdmissions : null,
            ArticleCategoryId.valarAcademicSupport,
            ArticleCategoryId.valarCareerServices,
            ArticleCategoryId.valarLibraryServices,
            ArticleCategoryId.valarStudentEvents,
        ],
        [ArticleSiteId.execEd]: [
            showAdmissionsTab ? ArticleCategoryId.execEdAdmissions : null,
            ArticleCategoryId.execEdAcademicSupport,
            ArticleCategoryId.execEdCareerServices,
            ArticleCategoryId.execEdLibraryServices,
            ArticleCategoryId.execEdStudentEvents,
        ],
    };

    const results =
        categories[siteId!]?.filter(typedBoolean).map(categoryId => ({
            title: categoriesTitles[categoryId],
            articles: articlesData ? articlesData.filter(article => article.categoryId === categoryId) : [],
        })) ?? [];

    const totalResults = results.reduce((counter, currentItem) => counter + currentItem.articles.length, 0);

    const filteredResults = results.filter(category => category.articles.length !== 0);

    const hasMoreResults =
        searchAllResult.articlesData && articlesData
            ? searchAllResult.articlesData.filter(item => !articlesData.some(({ id }) => id === item.id)).length > 0
            : false;

    return (
        <div className="tw-bg-white">
            <SearchResultsSection {...{ results: filteredResults, totalResults, status, hasMoreResults }} />
        </div>
    );
}
