export const breakPointsSize = {
    xs: 576,
    xsMax: 767,
    sm: 768,
    smMax: 991,
    md: 992,
    mdMax: 1199,
    lg: 1200,
    lgMax: 1599,
    xl: 1600,
};

export const breakpoints = {
    xs: `${breakPointsSize.xs}px`,
    xsMax: `${breakPointsSize.xsMax}px`,
    sm: `${breakPointsSize.sm}px`,
    smMax: `${breakPointsSize.smMax}px`,
    md: `${breakPointsSize.md}px`,
    mdMax: `${breakPointsSize.mdMax}px`,
    lg: `${breakPointsSize.lg}px`,
    lgMax: `${breakPointsSize.lgMax}px`,
    xl: `${breakPointsSize.xl}px`,
};

const getMediaQueryUp = (size: string) => `@media (min-width: ${size})`;
const getMediaQueryDown = (size: string) => `@media (max-width: ${size})`;
const getMediaQueryBetween = (sizeMin: string, sizeMax: string) =>
    `@media (min-width: ${sizeMin}) and (max-width: ${sizeMax})`;

export const mediaQuery = {
    upXs: getMediaQueryUp(breakpoints.xs),
    sm: getMediaQueryBetween(breakpoints.sm, breakpoints.smMax),
    upSm: getMediaQueryUp(breakpoints.sm),
    downSm: getMediaQueryDown(breakpoints.xsMax),
    md: getMediaQueryBetween(breakpoints.md, breakpoints.mdMax),
    upMd: getMediaQueryUp(breakpoints.md),
    downMd: getMediaQueryDown(breakpoints.smMax),
    lg: getMediaQueryBetween(breakpoints.lg, breakpoints.lgMax),
    upLg: getMediaQueryUp(breakpoints.lg),
    downLg: getMediaQueryDown(breakpoints.mdMax),
    upXl: getMediaQueryUp(breakpoints.xl),
    downXl: getMediaQueryDown(breakpoints.lgMax),
};
