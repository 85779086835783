import { Fragment, useEffect, useId, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { Form, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { Icon } from 'FrontRoyalIcon';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { useAngularContext } from 'AngularContext';
import { typedBoolean } from 'FrontRoyalAngular/misc';
import { type Cohort } from 'Cohorts';
import { getProgramConfigValue } from 'Program';
import { useAdmissionsTab } from '../useAdmissionsTab';
import { ROUTES } from '..';

const menuItemStyles = cva(
    'tw-pt-2.5 tw-pb-[9px] tw-px-3 tw-text-xs tw-leading-[17px] tw-tracking-[0.1px] tw-rounded tw-whitespace-nowrap tw-transition tw-ease-in-out md:tw-pt-2 md:tw-pb-[7px] md:tw-text-md md:tw-leading-[21px] md:tw-tracking-normal',
    {
        variants: {
            variant: {
                default: 'tw-text-white tw-bg-transparent hover:tw-bg-white/20',
                active: 'tw-text-beige-beyond-beyond-dark tw-bg-white tw-shadow',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

type AnimationStatus = 'paused' | 'entering' | 'leaving';

export function CategoryMenu({ cohort }: { cohort: Cohort }) {
    const { t } = useTranslation('back_royal');
    const injector = useAngularContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [{ query }] = useTypedSearchParams(ROUTES.RESOURCES.SEARCH);
    const formId = useId();
    const [searchOpen, setSearchOpen] = useState(location.pathname === ROUTES.RESOURCES.SEARCH.path);
    const [animationStatus, setAnimationStatus] = useState<AnimationStatus>('paused');
    const inputRef = useRef<HTMLInputElement>(null);
    const scrollHelper = injector.get<{ scrollToTop(smooth?: boolean): void }>('scrollHelper');
    const isExecEd = getProgramConfigValue(cohort.programType, 'isExecEd');

    const { showAdmissionsTab } = useAdmissionsTab();

    const categories = useMemo(
        () =>
            [
                showAdmissionsTab
                    ? { title: t('resources.resources.admissions'), href: ROUTES.RESOURCES.ADMISSIONS.path }
                    : null,
                {
                    title: t('resources.resources.academic_and_program_support'),
                    href: ROUTES.RESOURCES.ACADEMIC_AND_PROGRAM_SUPPORT.path,
                },
                { title: t('resources.resources.career_services'), href: ROUTES.RESOURCES.CAREER_SERVICES.path },
                !isExecEd
                    ? { title: t('resources.resources.library_services'), href: ROUTES.RESOURCES.LIBRARY_SERVICES.path }
                    : null,
                { title: t('resources.resources.student_events'), href: ROUTES.RESOURCES.STUDENT_EVENTS.path },
            ].filter(typedBoolean),
        [t, showAdmissionsTab, isExecEd],
    );

    const emptyCategory = useMemo(
        () => ({
            title: t('resources.resources.select_a_category'),
            href: '',
        }),
        [t],
    );
    const [category, setCategory] = useState(categories.find(c => c.href === location.pathname) ?? emptyCategory);

    useEffect(() => {
        if (location.state?.openSearch === true) {
            if (searchOpen) {
                scrollHelper.scrollToTop(true);
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 500);
            } else {
                setSearchOpen(true);
                scrollHelper.scrollToTop(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state]);

    useEffect(() => {
        if (location.pathname !== ROUTES.RESOURCES.SEARCH.path) {
            setSearchOpen(false);
            setCategory(categories.find(c => c.href === location.pathname) ?? emptyCategory);
        } else if (location.pathname === ROUTES.RESOURCES.SEARCH.path) {
            setCategory(emptyCategory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <div className="tw-relative tw-bg-coral valar:tw-bg-blue">
            <Transition
                show={!searchOpen}
                unmount={false}
                enter="tw-transition-opacity tw-duration-200 tw-delay-700 tw-ease-out"
                enterFrom="tw-opacity-0"
                enterTo="tw-opacity-100"
                leave="tw-transition-opacity tw-duration-150 tw-ease-in"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
            >
                <div
                    className={clsx(
                        'tw-absolute tw-top-0 tw-min-h-[56px] tw-w-[calc(100%-52px)] tw-py-2.5 sm:tw-hidden ltr:tw-left-0 ltr:tw-pl-5 ltr:tw-pr-3 rtl:tw-right-0 rtl:tw-pl-3 rtl:tw-pr-5',
                        searchOpen ? 'tw-z-10' : 'tw-z-20',
                    )}
                >
                    <div className="tw-relative tw-flex tw-w-full">
                        <Listbox
                            value={category}
                            onChange={value => {
                                setCategory(value);
                                navigate(value.href);
                            }}
                            disabled={searchOpen || animationStatus !== 'paused'}
                            by="href"
                        >
                            {({ open }) => (
                                <>
                                    <Listbox.Label className="sr-only">
                                        {t('resources.resources.category')}
                                    </Listbox.Label>
                                    <div className="tw-relative -tw-mx-1.5 tw-w-[calc(100%+12px)]">
                                        <Listbox.Button className="tw-relative tw-w-full tw-cursor-default tw-rounded tw-bg-transparent tw-pb-[7px] tw-pl-3 tw-pr-8 tw-pt-2 tw-text-left tw-text-md tw-leading-[21px] tw-text-white tw-transition tw-ease-in-out hover:tw-bg-white/20">
                                            <span className="tw-block tw-truncate">{parseHtml(category.title)}</span>
                                            <span className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
                                                <Icon
                                                    iconName="ChevronDown16"
                                                    className="tw-h-4 tw-w-4"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="tw-transition tw-ease-in tw-duration-100"
                                            leaveFrom="tw-opacity-100"
                                            leaveTo="tw-opacity-0"
                                        >
                                            <Listbox.Options className="tw-absolute tw-z-20 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1.5 tw-shadow tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                                                {categories.map(item => (
                                                    <Listbox.Option
                                                        key={item.href}
                                                        className="tw-relative tw-cursor-default tw-select-none tw-px-3 tw-py-2 tw-text-beige-beyond-beyond-dark ui-selected:tw-text-coral ui-active:tw-bg-beige ui-active:tw-text-coral valar:ui-selected:tw-text-blue valar:ui-active:tw-text-blue"
                                                        value={item}
                                                    >
                                                        <span className="tw-block tw-truncate">
                                                            {parseHtml(item.title)}
                                                        </span>
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                </div>
            </Transition>
            <div
                className={clsx(
                    'tw-relative tw-mx-auto tw-max-w-[1020px] tw-overflow-hidden tw-px-5 tw-py-2.5 sm:tw-px-8',
                    searchOpen ? 'tw-z-20' : 'tw-z-10',
                )}
            >
                <nav className="tw-relative tw-flex tw-h-9 tw-items-center tw-justify-between">
                    <div className="tw-hidden tw-h-9 tw-w-full sm:tw-flex ltr:tw-pr-10 rtl:tw-pl-10">
                        <Transition
                            show={!searchOpen}
                            className="hide-scrollbar tw-flex tw-grow tw-justify-center tw-gap-x-2 tw-overflow-x-auto tw-overscroll-none"
                            enter="tw-transition-opacity tw-duration-200 tw-delay-700 tw-ease-out"
                            enterFrom="tw-opacity-0"
                            enterTo="tw-opacity-100"
                            leave="tw-transition-opacity tw-duration-150 tw-ease-in"
                            leaveFrom="tw-opacity-100"
                            leaveTo="tw-opacity-0"
                        >
                            {categories.map(item => (
                                <NavLink
                                    key={item.title}
                                    to={item.href}
                                    className={({ isActive }) =>
                                        menuItemStyles({ variant: isActive ? 'active' : 'default' })
                                    }
                                >
                                    {parseHtml(item.title)}
                                </NavLink>
                            ))}
                        </Transition>
                    </div>
                    <div
                        className={clsx(
                            'tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-transform tw-items-center tw-transition-all tw-duration-700 tw-ease-in-out',
                            searchOpen
                                ? 'tw-translate-x-0'
                                : 'tw-delay-150 ltr:tw-translate-x-[calc(100%-30px)] sm:ltr:tw-translate-x-[calc(100%-24px)] md:ltr:tw-translate-x-[calc(100%-36px)] rtl:-tw-translate-x-[calc(100%-30px)] sm:rtl:-tw-translate-x-[calc(100%-24px)] md:rtl:-tw-translate-x-[calc(100%-36px)]',
                        )}
                    >
                        <div
                            className={clsx(
                                'tw-mx-auto tw-flex tw-w-full tw-items-center tw-transition-all tw-duration-700 tw-ease-in-out',
                                searchOpen
                                    ? 'tw-max-w-[788px] tw-px-1.5 sm:tw-px-0'
                                    : 'tw-max-w-full tw-px-0 tw-delay-150',
                            )}
                        >
                            <div className="tw-flex tw-w-[calc(100%+16px)] tw-items-center tw-gap-0.5 sm:tw-gap-2.5 ltr:-tw-ml-1.5 ltr:-tw-mr-2.5 rtl:-tw-ml-2.5 rtl:-tw-mr-1.5">
                                <button
                                    type={searchOpen && animationStatus === 'paused' ? 'submit' : 'button'}
                                    form={searchOpen && animationStatus === 'paused' ? formId : undefined}
                                    onClick={() => {
                                        if (!searchOpen && animationStatus === 'paused') {
                                            setSearchOpen(true);
                                        } else if (
                                            searchOpen &&
                                            animationStatus === 'paused' &&
                                            !inputRef.current?.value
                                        ) {
                                            inputRef.current?.focus();
                                        }
                                    }}
                                    className="tw-flex tw-rounded tw-border-none tw-bg-transparent tw-p-1.5 tw-text-white tw-transition tw-ease-in-out hover:tw-bg-white/20"
                                    disabled={animationStatus !== 'paused'}
                                >
                                    <span className="tw-sr-only">
                                        {searchOpen
                                            ? t('resources.resources.search')
                                            : t('resources.resources.open_search')}
                                    </span>
                                    <Icon iconName="Search24" className="tw-h-6 tw-w-6" aria-hidden="true" />
                                </button>
                                <Transition
                                    show={searchOpen}
                                    unmount={false}
                                    className="tw-flex-1"
                                    enter="tw-transition-opacity tw-duration-200 tw-delay-200 tw-ease-out"
                                    enterFrom="tw-opacity-0"
                                    enterTo="tw-opacity-100"
                                    leave="tw-transition-opacity tw-duration-150 tw-delay-200 tw-ease-in"
                                    leaveFrom="tw-opacity-100"
                                    leaveTo="tw-opacity-0"
                                    beforeEnter={() => setAnimationStatus('entering')}
                                    afterEnter={() => {
                                        setAnimationStatus('paused');
                                        inputRef.current?.focus();
                                    }}
                                    beforeLeave={() => setAnimationStatus('leaving')}
                                    afterLeave={() => {
                                        setAnimationStatus('paused');
                                        if (inputRef.current?.value) {
                                            inputRef.current.value = '';
                                        }
                                        if (location.state?.openSearch === true) {
                                            navigate(
                                                { pathname: location.pathname, search: location.search },
                                                { replace: true, state: {} },
                                            );
                                        }
                                    }}
                                >
                                    <Form
                                        id={formId}
                                        method="get"
                                        action={ROUTES.RESOURCES.SEARCH.path}
                                        onSubmit={e => {
                                            if (!inputRef.current?.value) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            name="query"
                                            placeholder={t('resources.resources.search_for_articles') as string}
                                            className="tw-w-full tw-border-none tw-bg-coral tw-py-2 tw-text-md tw-leading-5 tw-text-white tw-caret-white placeholder:tw-text-white/80 focus:tw-outline-none valar:tw-bg-blue"
                                            maxLength={64}
                                            defaultValue={query}
                                        />
                                    </Form>
                                </Transition>
                                <Transition
                                    show={searchOpen}
                                    unmount={false}
                                    enter="tw-transition-opacity tw-duration-200 tw-delay-700 tw-ease-out"
                                    enterFrom="tw-opacity-0"
                                    enterTo="tw-opacity-100"
                                    leave="tw-transition-opacity tw-duration-150 tw-ease-in"
                                    leaveFrom="tw-opacity-100"
                                    leaveTo="tw-opacity-0"
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setSearchOpen(false);
                                        }}
                                        className="tw-flex tw-rounded tw-border-none tw-bg-transparent tw-p-2.5 tw-text-white tw-transition tw-ease-in-out hover:tw-bg-white/20"
                                        disabled={animationStatus !== 'paused'}
                                    >
                                        <span className="tw-sr-only">{t('resources.resources.close_search')}</span>
                                        <Icon iconName="Close16" className="tw-h-4 tw-w-4" aria-hidden="true" />
                                    </button>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}
