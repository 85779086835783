import { type ReactNode, type ComponentProps } from 'react';
import { ArticleCard } from 'Resources/ArticleCard';
import { Typography } from 'Typography';

type ArticleGalleryProps = {
    title: string;
    subTitle?: string;
    items: ComponentProps<typeof ArticleCard>[];
    children?: ReactNode;
};

export function ArticleGallery({ title, subTitle, items, children }: ArticleGalleryProps) {
    return (
        <div className="tw-mx-auto tw-max-w-[555px] md:tw-max-w-full">
            <div>
                <Typography variant="header2" className="tw-uppercase tw-text-beige-beyond-beyond-dark">
                    {title}
                </Typography>
                {subTitle ? (
                    <Typography
                        variant="body3"
                        className="tw-mt-1 tw-uppercase tw-text-beige-beyond-beyond-dark md:tw-mt-2.5"
                    >
                        {subTitle}
                    </Typography>
                ) : null}
            </div>

            {items.length > 0 ? (
                <div className="tw-mt-7.5 tw-grid tw-grid-cols-1 tw-gap-7.5 md:tw-grid-cols-2">
                    {items.map(item => (
                        <ArticleCard
                            key={item.title}
                            {...item}
                            className="md:tw-flex md:tw-flex-col md:tw-justify-between"
                        />
                    ))}
                </div>
            ) : null}

            {children}
        </div>
    );
}
