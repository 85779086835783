import { Typography } from 'Typography';
import { Image } from 'Image';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { useTranslation } from 'react-i18next';
import { Container } from 'Container';
import { AnchorLink } from 'AnchorLink';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { validTopLevelReactRoutes } from 'FrontRoyalRouter';
import { type CategoryDescriptionSectionProps } from './CategoryDescriptionSection.types';

export function CategoryDescriptionSection({ header, content, image }: CategoryDescriptionSectionProps) {
    const { t } = useTranslation('back_royal');
    const { loadRoute } = useNavigationHelper();

    return (
        <section className="tw-py-7.5 md:tw-pb-10 md:tw-pt-13.5">
            <Container>
                <div className="tw-mx-auto tw-max-w-[555px] tw-space-y-8 md:tw-max-w-full md:tw-space-y-13.5">
                    <header className="tw-space-y-1 tw-text-center">
                        <Typography variant="smallText" className="tw-text-blue-grey/80">
                            {header.label || t('resources.resources.in_category')}
                        </Typography>
                        <div className="tw-flex tw-flex-col tw-items-center tw-gap-1 md:tw-flex-row md:tw-justify-center md:tw-gap-4">
                            <div className="tw-aspect-square tw-w-10">
                                <Image {...header.image} imgClassName="tw-mx-auto md:tw-mx-0" />
                            </div>
                            <Typography variant="header1" className="tw-text-grey-darker">
                                {header.title}
                            </Typography>
                        </div>
                    </header>
                    <div className="md:tw-grid md:tw-grid-cols-12 md:tw-gap-7.5">
                        <div className="tw-space-y-7.5 md:tw-col-span-6">
                            {content.map(item => (
                                <div key={item.title} className="tw-space-y-2.5">
                                    <Typography variant="header2" className="tw-text-grey-darker">
                                        {item.title}
                                    </Typography>
                                    {item.description && (
                                        <Typography
                                            variant="body2"
                                            className="tw-text-beige-beyond-beyond-dark"
                                            asChild={item.descriptionAsChild}
                                        >
                                            {parseHtml(item.description, {
                                                onLinkClick: (event, href) => {
                                                    const isRelative = href?.startsWith('/');
                                                    const basePath = href?.split('/')[1];
                                                    if (isRelative && !validTopLevelReactRoutes.includes(basePath)) {
                                                        event.preventDefault();
                                                        loadRoute(href!);
                                                    }
                                                    return null;
                                                },
                                            })}
                                        </Typography>
                                    )}

                                    {!!item.links?.length && (
                                        <ul className="tw-flex tw-flex-col tw-gap-4">
                                            {item.links.map(link => (
                                                <li key={link.label} className="tw-flex">
                                                    <AnchorLink
                                                        to={link.href}
                                                        withArrow
                                                        color="brand"
                                                        onClick={link.onClick}
                                                    >
                                                        <Typography variant="link" className="tw-font-medium">
                                                            {link.label}
                                                        </Typography>
                                                    </AnchorLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                    {item.subContent?.map(subItem => (
                                        <div key={subItem.title} className="tw-mt-2.5">
                                            <Typography
                                                variant="body2"
                                                className="tw-font-bold tw-uppercase tw-tracking-wider tw-text-grey-darkish"
                                            >
                                                {subItem.title}
                                            </Typography>
                                            {subItem.description && (
                                                <Typography
                                                    variant="body2"
                                                    className="tw-text-beige-beyond-beyond-dark"
                                                >
                                                    {parseHtml(subItem.description)}
                                                </Typography>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="tw-hidden md:tw-col-span-6 md:tw-block lg:tw-col-span-5 lg:tw-col-start-8">
                            <div className="tw-flex tw-aspect-[458/307] tw-items-center tw-justify-center">
                                <Image {...image} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
