import clsx from 'clsx';

type SlopeBackgroundProps = {
    className?: string;
};

export function SlopeBackground({ className }: SlopeBackgroundProps) {
    return (
        <div className={clsx('tw-absolute -tw-left-full tw-top-0 tw-h-full tw-w-[200%] tw-bg-beige', className)}>
            <div className="tw-absolute tw-left-0 tw-top-0 tw-h-64 tw-w-full tw-origin-top-right -tw-rotate-[5.5deg] -tw-scale-y-100 tw-transform tw-bg-white md:-tw-rotate-[3.1deg] [@media(min-width:1900px)]:-tw-rotate-2" />
        </div>
    );
}
