import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { useMemo } from 'react';
import { ProgramType } from 'Program';
import {
    type ProgramTypeToArticleCategoryIdMap,
    ArticleCategoryId,
    type ArticleSearchResult,
    type ResourcesAPIResponse,
    ArticleSiteId,
} from './Resources.types';
import { useSearchArticlesQuery } from './resourcesApi';
import { ROUTES } from './ResourcesRoutes';

export const getArticleCardsDetails = (items: ResourcesAPIResponse<ArticleSearchResult>['articles']['items']) =>
    items.map(item => ({
        id: item.id,
        title: item.name,
        description: item.preview,
        url: item.url,
        status: item.status,
        visibility: item.visibility,
        categoryId: item.categoryIds[0],
    }));

type UseSearchResultsProps = {
    query?: string;
    categorySlug?:
        | typeof ROUTES.RESOURCES.$.ADMISSIONS.relativePath
        | typeof ROUTES.RESOURCES.$.ACADEMIC_AND_PROGRAM_SUPPORT.relativePath
        | typeof ROUTES.RESOURCES.$.CAREER_SERVICES.relativePath
        | typeof ROUTES.RESOURCES.$.LIBRARY_SERVICES.relativePath
        | typeof ROUTES.RESOURCES.$.STUDENT_EVENTS.relativePath;
    searchAllSite: boolean;
};

const categoriesMap: Record<string, Record<ArticleSiteId, ArticleCategoryId | ProgramTypeToArticleCategoryIdMap>> = {
    [ROUTES.RESOURCES.$.ADMISSIONS.relativePath]: {
        // quantic has program-specific category ids, valar and execEd do not
        [ArticleSiteId.quantic]: {
            [ProgramType.mba]: ArticleCategoryId.quanticMbaEmbaAdmissions,
            [ProgramType.emba]: ArticleCategoryId.quanticMbaEmbaAdmissions,
            [ProgramType.msba]: ArticleCategoryId.quanticMsbaAdmissions,
            [ProgramType.msse]: ArticleCategoryId.quanticMsseAdmissions,
        },
        [ArticleSiteId.valar]: ArticleCategoryId.valarAdmissions,
        [ArticleSiteId.execEd]: ArticleCategoryId.execEdAdmissions,
    },
    [ROUTES.RESOURCES.$.ACADEMIC_AND_PROGRAM_SUPPORT.relativePath]: {
        [ArticleSiteId.quantic]: {
            [ProgramType.mba]: ArticleCategoryId.quanticMbaEmbaAcademicSupport,
            [ProgramType.emba]: ArticleCategoryId.quanticMbaEmbaAcademicSupport,
            [ProgramType.msba]: ArticleCategoryId.quanticMsbaAcademicSupport,
            [ProgramType.msse]: ArticleCategoryId.quanticMsseAcademicSupport,
        },
        [ArticleSiteId.valar]: ArticleCategoryId.valarAcademicSupport,
        [ArticleSiteId.execEd]: ArticleCategoryId.execEdAcademicSupport,
    },
    [ROUTES.RESOURCES.$.CAREER_SERVICES.relativePath]: {
        [ArticleSiteId.quantic]: {
            [ProgramType.mba]: ArticleCategoryId.quanticMbaEmbaCareerServices,
            [ProgramType.emba]: ArticleCategoryId.quanticMbaEmbaCareerServices,
            [ProgramType.msba]: ArticleCategoryId.quanticMsbaCareerServices,
            [ProgramType.msse]: ArticleCategoryId.quanticMsseCareerServices,
        },
        [ArticleSiteId.valar]: ArticleCategoryId.valarCareerServices,
        [ArticleSiteId.execEd]: ArticleCategoryId.execEdCareerServices,
    },
    [ROUTES.RESOURCES.$.LIBRARY_SERVICES.relativePath]: {
        [ArticleSiteId.quantic]: {
            [ProgramType.mba]: ArticleCategoryId.quanticMbaEmbaLibraryServices,
            [ProgramType.emba]: ArticleCategoryId.quanticMbaEmbaLibraryServices,
            [ProgramType.msba]: ArticleCategoryId.quanticMsbaLibraryServices,
            [ProgramType.msse]: ArticleCategoryId.quanticMsseLibraryServices,
        },
        [ArticleSiteId.valar]: ArticleCategoryId.valarLibraryServices,
        [ArticleSiteId.execEd]: ArticleCategoryId.execEdLibraryServices,
    },
    [ROUTES.RESOURCES.$.STUDENT_EVENTS.relativePath]: {
        [ArticleSiteId.quantic]: {
            [ProgramType.mba]: ArticleCategoryId.quanticMbaEmbaStudentEvents,
            [ProgramType.emba]: ArticleCategoryId.quanticMbaEmbaStudentEvents,
            [ProgramType.msba]: ArticleCategoryId.quanticMsbaStudentEvents,
            [ProgramType.msse]: ArticleCategoryId.quanticMsseStudentEvents,
        },
        [ArticleSiteId.valar]: ArticleCategoryId.valarStudentEvents,
        [ArticleSiteId.execEd]: ArticleCategoryId.execEdStudentEvents,
    },
};

function isArticleCategoryId(value: ArticleCategoryId | ProgramTypeToArticleCategoryIdMap): value is ArticleCategoryId {
    return typeof value === 'string' && Object.values(ArticleCategoryId).includes(value);
}

function getCategory(categorySlug: string, siteId: ArticleSiteId, programType: ProgramType): ArticleCategoryId {
    const siteValue = categoriesMap[categorySlug][siteId];
    if (isArticleCategoryId(siteValue)) {
        return siteValue;
    }
    if (programType in siteValue) {
        return siteValue[programType]!;
    }
    throw new Error(`No category found for siteId ${siteId} and programType ${programType}`);
}

export function useSearchResults({ query = '', categorySlug, searchAllSite }: UseSearchResultsProps) {
    // FIXME: migrate to useCurrentUser hook. Will need to migrate helpScoutArticleSiteId from schedulable.js to ProgramTypeConfigs.ts
    const currentUser = useCurrentUserIguanaObject();
    const siteId = currentUser?.relevantCohort?.helpScoutArticleSiteId;
    const programType = currentUser?.relevantCohort?.programType || ProgramType.mba;

    const {
        data: currentData,
        isLoading,
        isError,
    } = useSearchArticlesQuery(
        {
            query,
            categoryId: categorySlug ? getCategory(categorySlug, siteId!, programType) : undefined,
            page: 1,
            pageSize: 100,
            siteId: siteId!,
            searchAllSite,
            programType,
        },

        // prevent errors when currentUser changes to null on logout
        // (As long as this is only used on Resources pages, this isn't really
        // necessary, since Layout.tsx enforces that currentUser is not null, but
        // conceptually it makes sense)
        { skip: !siteId },
    );

    const articlesData = useMemo(() => {
        if (currentData && currentData?.items) {
            return getArticleCardsDetails(currentData.items);
        }
        return null;
    }, [currentData]);

    // eslint-disable-next-line no-nested-ternary
    const status = isError ? 'error' : isLoading ? 'loading' : 'success';
    return { articlesData, status } as const;
}
