import { useTranslation } from 'react-i18next';
import { useAppHeaderViewModel } from 'Navigation';
import { Outlet } from 'react-router-dom';
import { useCurrentUser } from 'FrontRoyalAngular';
import { getCohort } from 'Users';
import { CategoryMenu } from '../CategoryMenu';
import { Footer } from '../Footer';
import { WelcomeMessage } from '../WelcomeMessage';

export function Layout() {
    const currentUser = useCurrentUser();
    const cohort = getCohort(currentUser);
    const AppHeaderViewModel = useAppHeaderViewModel();
    const { t } = useTranslation();

    AppHeaderViewModel.setBodyBackground('beige');
    AppHeaderViewModel.showAlternateHomeButton = false;
    AppHeaderViewModel.setTitleHTML(t('resources.resources.resources_title').toLocaleUpperCase());

    if (!cohort) return null;

    return (
        <div className="tw-flex tw-animate-fade-in tw-flex-col sm:tw-min-h-[calc(100vh-75px)]">
            <div className="tw-flex-shrink-0 tw-bg-coral tw-py-[14px] valar:tw-bg-blue sm:tw-py-7.5">
                <CategoryMenu cohort={cohort} />
            </div>
            <div className="tw-relative tw-flex-grow">
                <Outlet />
                <WelcomeMessage />
            </div>
            <Footer className="tw-flex-shrink-0" />
        </div>
    );
}
